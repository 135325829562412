
<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <!-- 从文件列表打开，只能展示当前表单 -->
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload :isView="isView"
                        :url="imgUrl"
                        @exportForm="exportForm"
                        @download="download" />
      </template>
    </Pane>

    <div class="container">
      <Approval v-if="activeKey === '0'"
                     ref="approvalRef"
                     ></Approval>
      <ChangeApproval v-if="activeKey === '1'"
                      ref="changeApprovalRef"
                     ></ChangeApproval>
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import Approval from "./Approval.vue"; //管理计划审批
import ChangeApproval from "./ChangeApproval.vue"; //管理计划变更审批
export default {
  name: "planConstru",
  components: {
    ExportDownload,
    Approval,
    ChangeApproval,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/2-3.png"),

      activeKey: "0",
      tabList: [
        { key: "0", name: "施工进度计划审核", disabled: false },
        { key: "1", name: "施工进度计划变更审批", disabled: false },
      ],

      form: this.$form.createForm(this),
      pid: "", // 项目id

      isView: false,
    };
  },

  watch: {
    activeKey(newV) {
      if (newV === "0") {
        this.imgUrl = require("@/assets/epc/approvalFlow/2-3.png");
      } else {
        this.imgUrl = require("@/assets/epc/approvalFlow/construcChange.png");
      }
    },
  },
  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, isView, able, id } = query || {}; // 通过activeKey判断当前是哪个tab，id判断是否是编辑页面，isView判断是否是查看页面，

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid || "";
    this.isView = Boolean(isView) || false;

    // 前一个环节没有通过审批，后面tab不可点击；able表示第一个环节的审批状态
    //或从编辑进入页面，设置非活跃状态的tab不可用。
    if (id || able === "false") {
      this.setTab();
    }
  },
  methods: {
    setTab() {
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },


    download() {
      switch (this.activeKey) {
        case "0":
          this.$refs.approvalRef.download();
          break;
        case "1":
          this.$refs.changeApprovalRef.download();
          break;
      }
    },
    exportForm() {
      switch (this.activeKey) {
        case "0":
          this.$refs.approvalRef.exportForm();
          break;
        case "1":
          this.$refs.changeApprovalRef.exportForm();
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}
</style>
